import React, { useState } from "react";
import { Button, ButtonProps } from "@chakra-ui/button";
import { Box, Flex, HStack, ResponsiveValue, Spinner, Tooltip } from "@chakra-ui/react";
import { Loader } from "../loading/Loader";

type ToggleButtonOptions<T> = {
  value: T;
  label: string | JSX.Element;
  tooltip?: string;
};

interface ToggleButtonProps<T> extends Omit<ButtonProps, "onSelect"> {
  onSelect: (value: T) => void | Promise<void>;
  onDeselect?: () => void;
  selectedValue?: T;
  buttons: ToggleButtonOptions<T>[];
  width?: number;
  buttonColor?: string;
  selectedButtonColor?: string;
  size?: "sm" | "lg" | "md" | "xs";
  gap?: number;
}

export function ToggleButtons<T>({
  buttons,
  onSelect,
  onDeselect,
  selectedValue,
  buttonColor,
  selectedButtonColor,
  size,
  disabled,
  gap = 0,
  ...buttonProps
}: ToggleButtonProps<T>) {
  const [isSelecting, setSelecting] = useState(false);

  return (
    <>
      <Box display="flex" justifyContent="flex-start" position="relative" columnGap={gap}>
        {/* Loading overlay */}
        {isSelecting && (
          <>
            <Box width="100%" height="100%" position="absolute" background="black" opacity={0.05} />
            <Flex width="100%" height="100%" justifyContent="center" alignItems="center" position="absolute">
              <Spinner size="sm" />
            </Flex>
          </>
        )}
        {buttons.map((button, index) => {
          const isSelected = selectedValue === button.value;
          console.log({ selectedValue, buttonValue: button.value, isSelected });
          return (
            <Tooltip key={index} label={button.tooltip} placement="left" hasArrow>
              <Button
                size={size || "sm"}
                borderRadius={2}
                p={size === "xs" ? 2 : "auto"}
                height={size === "xs" ? 5 : "auto"}
                fontSize="12px"
                backgroundColor={isSelected ? selectedButtonColor || "black" : buttonColor}
                color={isSelected ? "white" : undefined}
                variant={isSelected ? "solid" : "solid"}
                key={
                  typeof button.label === "string"
                    ? `toggle-button-${button.label}`
                    : `toggle-button-${index}`
                }
                onClick={
                  disabled
                    ? undefined
                    : async () => {
                        if (isSelected) {
                          if (onDeselect) {
                            onDeselect();
                          }
                          return;
                        }

                        setSelecting(true);
                        await onSelect(button.value);
                        setSelecting(false);
                      }
                }
                opacity={disabled ? 0.4 : 1}
                _hover={{
                  opacity: isSelected && !disabled ? 1 : disabled ? 0.4 : 0.7,
                }}
                _focus={{
                  outline: "none",
                }}
                _active={{
                  bg: disabled ? "auto" : "gray.600",
                }}
                cursor={isSelected ? "default" : "pointer"}
                overflow="hidden"
                flexGrow={1}
                flexBasis={1}
                disabled={isSelecting || disabled}
                {...buttonProps}
              >
                {button.label}
              </Button>
            </Tooltip>
          );
        })}
      </Box>
    </>
  );
}
